import "@/styles/globals.css"
import type { AppProps } from "next/app"
import theme from "../styles/theme"
import GlobalHeaderComponent from "../components/GlobalHeaderComponent"
import MobileGlobalHeaderComponent from "../components/MobileGlobalHeaderComponent"
import * as amplitude from "@amplitude/analytics-browser"
import { ChakraProvider, Box } from "@chakra-ui/react"
import { useMediaQuery } from "@chakra-ui/react"
import Script from "next/script"
import { NextIntlClientProvider } from "next-intl"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useRef } from "react"
import FacebookPixel from "../components/FacebookPixel"
import useMemberStore from "@/store/MemberStore"

const lang = require("@/messages/App.json")

export default function App({ Component, pageProps }: AppProps) {
  const [isDesktop] = useMediaQuery("(min-width: 800px)")
  const [isMobile] = useMediaQuery("(max-width: 480px)")
  const GAID = process.env.NEXT_PUBLIC_GA_ID
  const router = useRouter()
  const { member } = useMemberStore()
  const { locale } = router
  const { pathname } = router
  const t = lang[locale]
  const isLandingPage = pathname === "/features/keyword"
  const maxWidth = isLandingPage || isMobile ? "100%" : "1100px"
  const minWidth = isDesktop ? "600px" : "100%"

  const header = isMobile ? (
    <MobileGlobalHeaderComponent locale={router.locale} />
  ) : (
    <GlobalHeaderComponent locale={router.locale} />
  )

  amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_ID, null, {
    minIdLength: 1,
  })

  return (
    <NextIntlClientProvider
      locale={router.locale}
      timeZone="Asia/Taipei"
      messages={pageProps.messages}>
      <ChakraProvider theme={theme}>
        <FacebookPixel />
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=G-${GAID}`}
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-${GAID}');
        `}
        </Script>

        {header}
        <Box
          bg="ui.foreground1"
          maxWidth={maxWidth}
          minWidth={minWidth}
          marginLeft={"auto"}
          marginRight={"auto"}>
          <Component {...pageProps} />
        </Box>
      </ChakraProvider>
    </NextIntlClientProvider>
  )
}
